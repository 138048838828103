import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './home.css'; // Archivo CSS para el estilo del Home
import image1 from '../assets/image1-min.jpeg';
import image2 from '../assets/image2-min.jpeg';
import image3 from '../assets/image3-min.jpeg';
import image4 from '../assets/image4-min.jpeg';
import logo from '../assets/frutos-min.png';
import cafe1 from '../assets/coffee1-min.png';
import cafe2 from '../assets/coffee2-min.png';
import cafe3 from '../assets/coffee3-min.png';
import cacao1 from '../assets/Cocoa1-min.png';
import cacao2 from '../assets/cocoa2-min.png';
import cacao3 from '../assets/cocoa3-min.png';
import corner1 from '../assets/esq1-min.png';
import corner2 from '../assets/esq3-min.png';
import corner3 from '../assets/esq4-min.png';
import corner4 from '../assets/esq23-min.png';
import horizontal1 from '../assets/logo1-min.png';
import horizontal2 from '../assets/logo2-min.png';
import horizontal3 from '../assets/logo3-min.png';
import comingSoon from '../assets/coming-soon-min.png';

const Home = () => {
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [cafeIndex, setCafeIndex] = useState(0);
  const [cacaoIndex, setCacaoIndex] = useState(0);
  const mainIntervalTime = 5000; // Tiempo en milisegundos para el carrusel principal
  const productIntervalTime = 5000; // Tiempo en milisegundos para los carruseles de productos

  const images = [image1, image2, image3, image4];
  const imagesCafe = [cafe1, cafe2, cafe3];
  const imagesCacao = [cacao1, cacao2, cacao3];
  const cafeNames = ["Pink Bourbon", "Geisha", "Blend"];
  const cacaoNames = ["Cacao Product 1", "Cacao Product 2", "Cacao Product 3"];

  const resetInterval = (setIndex, length) => {
    return setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % length);
    }, productIntervalTime);
  };

  useEffect(() => {
    const mainInterval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, mainIntervalTime);

    const cafeInterval = resetInterval(setCafeIndex, imagesCafe.length);
    const cacaoInterval = resetInterval(setCacaoIndex, imagesCacao.length);

    return () => {
      clearInterval(mainInterval);
      clearInterval(cafeInterval);
      clearInterval(cacaoInterval);
    };
  }, [images.length, imagesCafe.length, imagesCacao.length]);

  useEffect(() => {
    const carouselImages = document.querySelectorAll('.carousel-image');
    carouselImages.forEach((img, index) => {
      img.style.opacity = index === currentIndex ? '1' : '0';
    });
  }, [currentIndex]);

  useEffect(() => {
    const cafeImages = document.querySelectorAll('.cafe-carousel .carousel-item img');
    cafeImages.forEach((img, index) => {
      img.style.opacity = index === cafeIndex ? '1' : '0';
    });
  }, [cafeIndex]);

  useEffect(() => {
    const cacaoImages = document.querySelectorAll('.cacao-carousel .carousel-item img');
    cacaoImages.forEach((img, index) => {
      img.style.opacity = index === cacaoIndex ? '1' : '0';
    });
  }, [cacaoIndex]);

  const showNextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const showPrevImage = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const showNextCafeImage = () => {
    setCafeIndex((cafeIndex + 1) % imagesCafe.length);
  };

  const showPrevCafeImage = () => {
    setCafeIndex((cafeIndex - 1 + imagesCafe.length) % imagesCafe.length);
  };

  const showNextCacaoImage = () => {
    setCacaoIndex((cacaoIndex + 1) % imagesCacao.length);
  };

  const showPrevCacaoImage = () => {
    setCacaoIndex((cacaoIndex - 1 + imagesCacao.length) % imagesCacao.length);
  };

  const cafeProduct = `Me gustaría cotizar el producto de café: ${cafeNames[cafeIndex]}`;
  const cacaoProduct = `Me gustaría cotizar el producto de cacao: ${cacaoNames[cacaoIndex]}`;

  return (
    <div className="home">
      <Helmet>
        <title>Crossmi - Home</title>
        <meta name="description" content="Discover the finest Colombian coffee and cacao with Crossmi." />
        <meta name="keywords" content="coffee, cacao, Colombia, Crossmi, premium quality" />
      </Helmet>
      <div className="carousel">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            className={`carousel-image ${index === currentIndex ? 'fade' : ''}`}
          />
        ))}
        <button className="carousel-button prev" onClick={showPrevImage}>&#10094;</button>
        <button className="carousel-button next" onClick={showNextImage}>&#10095;</button>
        <div className="carousel-text-box">
          <img src={logo} alt="Logo" className="carousel-logo" />
          <div>
            <h2>{t('from_our_fields')}</h2>
            <p>{t('thank_you')}</p>
          </div>
        </div>
      </div>
      <div className="divider1"></div>
      <div className="horizontal-images">
        <div className="horizontal-item">
          <img src={horizontal1} alt="Imagen Horizontal 1" className="horizontal-image" />
          <p>{t('coffee_from_colombia')}</p>
        </div>
        <div className="horizontal-item">
          <img src={horizontal2} alt="Imagen Horizontal 2" className="horizontal-image" />
          <p>{t('protected_designation')}</p>
          <p>{t('pdi_colombia')}</p>
        </div>
        <div className="horizontal-item">
          <img src={horizontal3} alt="Imagen Horizontal 3" className="horizontal-image" />
          <p>{t('protected_geographical')}</p>
        </div>
      </div>
      <div className="corner-images top">
        <img src={corner1} alt="Imagen Esquina 1" className="corner-image top-left" />
        <img src={corner2} alt="Imagen Esquina 2" className="corner-image top-right" />
      </div>
      <div className="divider-left1"></div>
      <div className="section visible">
        <h2 className="product-title">{t('premium_quality_coffee')}</h2>
        <h3 className="product-subheading">{t('exquisite_flavors')}</h3>
        <div className="cafe-carousel">
          {imagesCafe.map((image, index) => (
            <div key={index} className="carousel-item">
              <img src={image} alt={`Café Product ${index + 1}`} className="carousel-image" />
            </div>
          ))}
          <button className="carousel-button prev" onClick={showPrevCafeImage}>&#10094;</button>
          <button className="carousel-button next" onClick={showNextCafeImage}>&#10095;</button>
        </div>
        <a href={`https://wa.me/573156299567?text=${encodeURIComponent(cafeProduct)}`} target="_blank" rel="noopener noreferrer" className="cafe-buy-button">{t('buy_now')}</a>
      </div>
      <div className="divider2"></div>
      <div className="section visible">
        <h2 className="product-title">{t('delicious_cacao_moments')}</h2>
        <h3 className="product-subheading">{t('sweetness_purity')}</h3>
        <div className="cacao-carousel">
          {imagesCacao.map((image, index) => (
            <div key={index} className="carousel-item">
              <img src={image} alt={`Cacao Product ${index + 1}`} className="carousel-image" />
            </div>
          ))}
          <div className="coming-soon-container">
            <img src={comingSoon} alt="Coming Soon" className="coming-soon-image" />
          </div>
          <button className="carousel-button prev" onClick={showPrevCacaoImage}>&#10094;</button>
          <button className="carousel-button next" onClick={showNextCacaoImage}>&#10095;</button>
        </div>
        <a href={`https://wa.me/573156299567?text=${encodeURIComponent(cacaoProduct)}`} target="_blank" rel="noopener noreferrer" className="cacao-buy-button">{t('buy_now')}</a>
      </div>
      <div className="corner-images bottom">
        <img src={corner3} alt="Imagen Esquina 3" className="corner-image bottom-left" />
        <img src={corner4} alt="Imagen Esquina 4" className="corner-image bottom-right" />
      </div>
    </div>
  );
};

export default Home;
