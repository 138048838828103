import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logocafe-min.png';
import usFlag from '../assets/flags/en.png';
import esFlag from '../assets/flags/es.png';
import './header.css';

const Header = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [productsMenuOpen, setProductsMenuOpen] = useState(false);
  const languageRef = useRef(null);
  const productsRef = useRef(null);

  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  const toggleProductsMenu = () => {
    setProductsMenuOpen(!productsMenuOpen);
  };

  const handleClickOutside = useCallback((event) => {
    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setLanguageMenuOpen(false);
    }
    if (productsRef.current && !productsRef.current.contains(event.target)) {
      setProductsMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="top-bar">
      <Link to="/" className="app-logo-link">
        <img src={logo} alt="Crossmi Logo" className="app-logo" />
      </Link>
      <ul className="ul">
        <li className="li">
          <Link to="/" className="button"><p className="p">{i18n.t('home')}</p></Link>
        </li>
        <li className="li">
          <Link to="/about" className="button"><p className="p">{i18n.t('about_us')}</p></Link>
        </li>
        <li className="li">
          <Link to="/origins" className="button"><p className="p">{i18n.t('origins')}</p></Link>
        </li>
        <li className="li" ref={productsRef}>
          <div className="button" onClick={toggleProductsMenu}>
            <p className="p">{i18n.t('products')}</p>
          </div>
          {productsMenuOpen && (
            <div className="dropdown-menu">
              <Link to="/cafe" className="dropdown-item">{i18n.t('cafe')}</Link>
              <Link to="/cacao" className="dropdown-item">{i18n.t('cacao')}</Link>
            </div>
          )}
        </li>
        <li className="li language-selector" ref={languageRef}>
          <div className="current-language" onClick={toggleLanguageMenu}>
            <img src={currentLanguage === 'en' ? usFlag : esFlag} alt={currentLanguage === 'en' ? 'English' : 'Español'} />
            <span>{currentLanguage === 'en' ? 'English' : 'Español'}</span>
          </div>
          {languageMenuOpen && (
            <div className="language-options">
              {currentLanguage !== 'en' && (
                <div onClick={() => changeLanguage('en')}>
                  <img src={usFlag} alt="English" />
                  <span>English</span>
                </div>
              )}
              {currentLanguage !== 'es' && (
                <div onClick={() => changeLanguage('es')}>
                  <img src={esFlag} alt="Español" />
                  <span>Español</span>
                </div>
              )}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Header;
