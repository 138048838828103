import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Traducciones
const resources = {
  en: {
    translation: {
      from_our_fields: "From our fields to your cup",
      thank_you: "Thank you for being a part of our journey!",
      coffee_from_colombia: "Coffee from Colombia",
      protected_designation: "Protected Designation of Origin",
      pdi_colombia: "(PDO) - Colombia",
      protected_geographical: "Protected Geographical Indication (PGI)",
      premium_quality_coffee: "Premium Quality Coffee: A Luxurious Experience",
      exquisite_flavors: "Enjoy the Most Exquisite and Exclusive Flavors",
      buy_now: "Buy Now",
      delicious_cacao_moments: "Delicious Moments with Our Cacao",
      sweetness_purity: "The Sweetness and Purity of Colombian Cacao",
      contact_us: "Contact Us",
      connect_us: "Connect Us",
      home: "Home",
      about_us: "About Us",
      origins: "Origins",
      products: "Products",
      cafe: "Coffe",
      cacao: "Cocoa",
      about_us_text: "At CROSSMI, a registered trademark of FRUTOSEVERGREEN, we are dedicated to offering the world the finest Colombian coffee and cacao. Our passion for these exceptional products drives us to act as intermediaries committed to quality, sustainability, and authenticity. We work closely with producing communities to ensure that every coffee bean and cacao pod meets the highest standards of excellence.",
      what_we_do: "What Do We Do",
      what_we_do_text: "At CROSSMI and FRUTOSEVERGREEN, we carefully select the best Colombian coffee and cacao directly from the most prestigious regions. We implement rigorous quality controls, facilitate the export process, and promote sustainable agricultural practices. We strive to connect coffee and cacao lovers worldwide with the authentic essence of Colombia, offering products that reflect our dedication to quality and sustainability.",
      discover_origins: "Discover the Origins of Our Products",
      from_mountains: "From the Mountains of Colombia to Your Cup",
      map_coffee_origins_alt: "Map of Coffee Origins",
      pure_essence: "At CROSSMI, we are dedicated to bringing you the pure essence of Colombian coffee. Our commitment to sustainability, quality, and authenticity ensures that every cup you enjoy is not just a beverage, but a journey to the vibrant hills of Tolima. Indulge in the flavors of Colombia and experience the passion and tradition that make our coffee truly unique.",
      discover_origins_excellence: "Discover the origins. Savor the excellence.",
      cacao_products: "Cacao Products",
      cacao_product_1_alt: "Cacao Product 1",
      cacao_product_2_alt: "Cacao Product 2",
      cacao_product_3_alt: "Cacao Product 3",
      coffee_products: "Coffee Products",
      coffee_product_1_alt: "Coffee Product 1",
      coffee_product_2_alt: "Coffee Product 2",
      coffee_product_3_alt: "Coffee Product 3",
    
    },
  },
  es: {
    translation: {
      from_our_fields: "De nuestros campos a tu taza",
      thank_you: "¡Gracias por ser parte de nuestro viaje!",
      coffee_from_colombia: "Café de Colombia",
      protected_designation: "Denominación de Origen Protegida",
      pdi_colombia: "(DOP) - Colombia",
      protected_geographical: "Indicación Geográfica Protegida (IGP)",
      premium_quality_coffee: "Café de Calidad Premium: Una Experiencia de Lujo",
      exquisite_flavors: "Disfruta de los Sabores Más Exquisitos y Exclusivos",
      buy_now: "Comprar Ahora",
      delicious_cacao_moments: "Momentos Deliciosos con Nuestro Cacao",
      sweetness_purity: "La Dulzura y Pureza del Cacao Colombiano",
      contact_us: "Contáctanos",
      connect_us: "Conéctanos",
      home: "Inicio",
      about_us: "Sobre Nosotros",
      origins: "Orígenes",
      products: "Productos",
      cafe: "Café",
      cacao: "Cacao",
      about_us_text: "En CROSSMI, una marca registrada de FRUTOSEVERGREEN, estamos dedicados a ofrecer al mundo el mejor café y cacao colombiano. Nuestra pasión por estos productos excepcionales nos impulsa a actuar como intermediarios comprometidos con la calidad, la sostenibilidad y la autenticidad. Trabajamos estrechamente con las comunidades productoras para asegurar que cada grano de café y cada vaina de cacao cumplan con los más altos estándares de excelencia.",
      what_we_do: "Qué Hacemos",
      what_we_do_text: "En CROSSMI y FRUTOSEVERGREEN, seleccionamos cuidadosamente el mejor café y cacao colombiano directamente de las regiones más prestigiosas. Implementamos rigurosos controles de calidad, facilitamos el proceso de exportación y promovemos prácticas agrícolas sostenibles. Nos esforzamos por conectar a los amantes del café y el cacao de todo el mundo con la esencia auténtica de Colombia, ofreciendo productos que reflejan nuestra dedicación a la calidad y la sostenibilidad.",
      discover_origins: "Descubre los Orígenes de Nuestros Productos",
      from_mountains: "Desde las Montañas de Colombia hasta tu Taza",
      map_coffee_origins_alt: "Mapa de los Orígenes del Café",
      pure_essence: "En CROSSMI, estamos dedicados a traerte la esencia pura del café colombiano. Nuestro compromiso con la sostenibilidad, la calidad y la autenticidad asegura que cada taza que disfrutes no sea solo una bebida, sino un viaje a las vibrantes colinas de Tolima. Disfruta de los sabores de Colombia y experimenta la pasión y la tradición que hacen que nuestro café sea verdaderamente único.",
      discover_origins_excellence: "Descubre los orígenes. Saborea la excelencia.",
      cacao_products: "Productos de Cacao",
      cacao_product_1_alt: "Producto de Cacao 1",
      cacao_product_2_alt: "Producto de Cacao 2",
      cacao_product_3_alt: "Producto de Cacao 3",
      coffee_products: "Productos de Café",
      coffee_product_1_alt: "Producto de Café 1",
      coffee_product_2_alt: "Producto de Café 2",
      coffee_product_3_alt: "Producto de Café 3",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
