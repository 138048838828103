import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './about.css';
import image1 from '../assets/image5-min.JPG';
import image2 from '../assets/image6-min.jpeg';
import corner1 from '../assets/esq2-min.png';
import corner2 from '../assets/esq23-min.png';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <Helmet>
        <title>Crossmi - About Us</title>
        <meta name="description" content="Learn more about Crossmi and our commitment to providing the finest Colombian coffee and cacao." />
      </Helmet>
      <img src={corner1} alt="Corner 1" className="top-right" />
      <div className="about-section">
        <div className="about-image">
          <img src={image1} alt="Imagen Misión" />
        </div>
        <div className="about-text">
          <h2>{t('about_us')}</h2>
          <p>
            {t('about_us_text')}
          </p>
        </div>
      </div>
      <div className="about-section reverse">
        <div className="about-text">
          <h2>{t('what_we_do')}</h2>
          <p>
            {t('what_we_do_text')}
          </p>
        </div>
        <div className="about-image">
          <img src={image2} alt="Imagen Visión" />
        </div>
      </div>
      <img src={corner2} alt="Corner 2" className="bottom-left" />
    </div>
  );
};

export default About;
