import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Cacao from './pages/cacao';
import Coffee from './pages/cafe';
import Origins from './pages/origins';
import Header from './components/header';
import Footer from './components/footer';
import { Helmet } from 'react-helmet';
import './app.css';

const App = () => {
  return (
    <Router>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        />
      </Helmet>
      <Header />
      <div className="app-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/cacao" element={<Cacao />} />
          <Route path="/cafe" element={<Coffee />} />
          <Route path="/origins" element={<Origins />} />
        </Routes>
      </div>
      <Footer /> {/* Incluyendo el footer */}
    </Router>
  );
};

export default App;
