import React from 'react';
import newsletterImage from '../assets/frutoso-min.png'; // Asegúrate de tener esta imagen en tu carpeta de assets
import footerBackground from '../assets/footer-min.jpeg'; // Asegúrate de tener esta imagen en tu carpeta de assets
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundImage: `url(${footerBackground})` }}>
      <div className="footer-background">
        <div className="footer-content">
          <div className="footer-columns">
            <div className="footer-column contacto">
              <h3>Contact Us</h3>
              <ul>
                <li><a href="mailto:info@crossmi.com">Comercial@frutosevergreen.com</a></li>
                <li><a href="tel:3156299567">+57 3156299567</a></li>
              </ul>
            </div>
            <div className="footer-column conectar">
              <h3>Conect Us </h3>
              <ul className="social-icons">
                <li><a href="https://www.instagram.com/frutos_evergreen?igsh=bDMyYmltN3c4OHlr&utm_source=qr"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://wa.me/573156299567"><i className="fab fa-whatsapp"></i></a></li>
                <li><a href="https://www.linkedin.com/in/frutos-evergreen/"><i className="fab fa-linkedin"></i></a></li>
              </ul>
            </div>
            <div className="footer-logo">
              <img src={newsletterImage} alt="Newsletter" className="newsletter-image" />
            </div>
          </div>
          <div className="footer-bottom">
            <p>© 2024 Crossmi. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;