import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './origins.css';
import mapCoffee from '../assets/mapacafe-min.png'; // Asegúrate de tener esta imagen en tu carpeta de assets

const Origins = () => {
  const { t } = useTranslation();

  return (
    <div className="origins-container">
      <Helmet>
        <title>Crossmi - Origins</title>
        <meta name="description" content="Discover the origins of Crossmi's coffee and cacao products." />
      </Helmet>
      <h1>{t('discover_origins')}</h1>
      <h2>{t('from_mountains')}</h2>
      <div className="map-container">
        <img src={mapCoffee} alt={t('map_coffee_origins_alt')} className="map-image" />
      </div>
      <div className="text-below-map">
        <p>{t('pure_essence')}</p>
        <p>{t('discover_origins_excellence')}</p>
      </div>
    </div>
  );
};

export default Origins;
