import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './cacao.css';
import cacao1 from '../assets/Cocoa1-min.png';
import cacao2 from '../assets/cocoa2-min.png';
import cacao3 from '../assets/cocoa3-min.png';

const Cacao = () => {
  const { t } = useTranslation();

  return (
    <div className="cacao-container">
      <Helmet>
        <title>Crossmi - Cacao Products</title>
        <meta name="description" content="Discover the finest cacao products from Crossmi." />
      </Helmet>
      <h2 className="section-title">{t('cacao_products')}</h2>
      <div className="cacao-images">
        <div className="cacao-image">
          <img src={cacao1} alt={t('cacao_product_1_alt')} />
        </div>
        <div className="cacao-image">
          <img src={cacao2} alt={t('cacao_product_2_alt')} />
        </div>
        <div className="cacao-image">
          <img src={cacao3} alt={t('cacao_product_3_alt')} />
        </div>
      </div>
    </div>
  );
};

export default Cacao;
