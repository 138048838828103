import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './cafe.css';
import cafe1 from '../assets/coffee1-min.png';
import cafe2 from '../assets/coffee2-min.png';
import cafe3 from '../assets/coffee3-min.png';

const Cafe = () => {
  const { t } = useTranslation();

  return (
    <div className="cafe-container">
      <Helmet>
        <title>Crossmi - Coffee Products</title>
        <meta name="description" content="Discover the finest coffee products from Crossmi." />
      </Helmet>
      <h2 className="section-title">{t('coffee_products')}</h2>
      <div className="cafe-images">
        <div className="cafe-image">
          <img src={cafe1} alt={t('coffee_product_1_alt')} />
        </div>
        <div className="cafe-image">
          <img src={cafe2} alt={t('coffee_product_2_alt')} />
        </div>
        <div className="cafe-image">
          <img src={cafe3} alt={t('coffee_product_3_alt')} />
        </div>
      </div>
    </div>
  );
};

export default Cafe;
